import { combineReducers } from '@reduxjs/toolkit';
import auth from './reducers/auths.slice';
import common from './reducers/common.slice';
import booking from './reducers/Booking/booking.slice';
import orderProduct from './reducers/Booking/orderproduct.slice';
import coupons from './reducers/Booking/coupon.slice';
import about from './reducers/About/about.slice';
import venue from './reducers/Venue/venue.slice';
import enquires from './reducers/Enquires/enquires.slice';
import requestABrochure from './reducers/RequestABrochure/requestABrochure.slice';
// import user from './reducers/User/user.slice';
import guest from './reducers/Booking/guest.slice';
import googlePlace from './reducers/googlePlaceApi.slice';
import gallery from './reducers/Gallery/gallery.slice';

export function createReducer(injectedReducers) {
  return combineReducers({
    ...injectedReducers,
    auth,
    googlePlace,
    common,
    booking,
    orderProduct,
    coupons,
    guest,
    about,
    venue,
    enquires,
    requestABrochure,
    // user,
    gallery,
  });
}
