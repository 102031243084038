import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from '../common.slice';
const initialState = {
  gallery: [],
};

export const getGallery = createAsyncThunk(
  'admin/get-gallery',
  (booking_id, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/booking/${booking_id}/gallery`)
        .then(res => {
          if (res.data.err === 0) {
            if (res.data.data.gallery.length > 0) {
              resolve(res.data.data.gallery);
            } else {
              resolve([]);
            }
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {},
  extraReducers: {
    [getGallery.fulfilled]: (state, action) => {
      state.gallery = action.payload;
    },
  },
});

export default gallerySlice.reducer;
