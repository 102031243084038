import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from '../common.slice';

const initialState = {
  couponLoading: false,
  createCouponData: {},
  couponTypeAvailableData: {},
  couponTypeBookingData: {},
  couponAgentData: {},
};

export const createCoupon = createAsyncThunk(
  'admin/create-couons-as',
  (coupons, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const payload = {
        booking_id: coupons.booking_id,
        coupon_id: coupons.coupon_id,
        type: coupons.type,
      };
      axios
        .post('api/coupons', payload)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
            coupons.handleValidCommon(false);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
            coupons.handleValidCommon(false);
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
          if (error.response.data.msg.includes('invalid coupon')) {
            coupons.handleValidCommon(true);
          }
        });
    });
  },
);

export const couponTypeAvailable = createAsyncThunk(
  'admin/create-coupon-available-as',
  (coupons, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/coupons', coupons)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const couponTypeBooking = createAsyncThunk(
  'admin/cr-coupon-bookings-as',
  (coupons, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('api/coupons', coupons)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const couponAgentDataApi = createAsyncThunk(
  'admin/cr-coupon-agent-data-as',
  (agent, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/bpe-user/ifAgent', agent)
        .then(res => {
          if (res.data.err === 0) {
            // dispatch(
            //   showMessage({ message: res.data.msg, varient: 'success' }),
            // );
            resolve(res.data?.data);
          } else {
            dispatch(showMessage({ message: res.data.msg }));
            reject();
          }
        })
        .catch(error => {
          dispatch(showMessage({ message: error.response.data.msg }));
          reject(error);
        });
    });
  },
);

export const orderProductSlice = createSlice({
  name: 'orderProduct',
  initialState,
  reducers: {
    setOrderProductLoading: (state, action) => {
      state.couponLoading = action.payload;
    },
    setIsOrderProductUpdated: (state, action) => {
      state.isCouponUpdated = action.payload;
    },
  },
  extraReducers: {
    [createCoupon.pending]: state => {
      state.isCouponUpdated = false;
      state.couponLoading = true;
    },
    [createCoupon.rejected]: state => {
      state.createCouponData = {};
      state.isCouponUpdated = false;
      state.couponLoading = false;
    },
    [createCoupon.fulfilled]: (state, action) => {
      state.createCouponData = action.payload;
      state.isCouponUpdated = true;
      state.couponLoading = false;
    },
    [couponTypeAvailable.pending]: state => {
      state.isCouponUpdated = false;
      state.couponLoading = true;
    },
    [couponTypeAvailable.rejected]: state => {
      state.couponTypeAvailableData = {};
      state.isCouponUpdated = false;
      state.couponLoading = false;
    },
    [couponTypeAvailable.fulfilled]: (state, action) => {
      state.couponTypeAvailableData = action.payload;
      state.isCouponUpdated = true;
      state.couponLoading = false;
    },
    [couponTypeBooking.pending]: state => {
      state.couponLoading = true;
    },
    [couponTypeBooking.rejected]: state => {
      state.couponTypeBookingData = {};
      state.couponLoading = false;
    },
    [couponTypeBooking.fulfilled]: (state, action) => {
      state.couponTypeBookingData = action.payload;
      state.couponLoading = false;
    },
    [couponAgentDataApi.pending]: state => {
      state.couponLoading = true;
    },
    [couponAgentDataApi.rejected]: state => {
      state.couponAgentData = {};
      state.couponLoading = false;
    },
    [couponAgentDataApi.fulfilled]: (state, action) => {
      state.couponAgentData = action.payload;
      state.couponLoading = false;
    },
  },
});

export const { setOrderProductLoading, setIsOrderProductUpdated } =
  orderProductSlice.actions;

export default orderProductSlice.reducer;
