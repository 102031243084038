import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Routes from 'routes/index';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import GetCookies from 'hooks/GetCookies';
import { setIsUserLogin } from 'store/reducers/auths.slice';
import RemoveCookies from 'hooks/RemoveCookies';
import SetCookies from 'hooks/SetCookies';
import { showMessage } from 'store/reducers/common.slice';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let reportParamData = Object.fromEntries([...searchParams]);
  const location = useLocation();

  useEffect(() => {
    const values = GetCookies('Token');
    if (values) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${atob(values)}`;
      dispatch(setIsUserLogin(true));
      RemoveCookies('Token');
      SetCookies('Token', btoa(atob(values)));
    }

    localStorage.removeItem('bookplaces');
    reportParamData?.event_date_id &&
      reportParamData?.approx_places &&
      localStorage.setItem('book_places', JSON.stringify(reportParamData));
    reportParamData?.source &&
      localStorage.setItem('source', reportParamData.source);
    const interceptor = axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          console.log('status is 401');
          RemoveCookies('UserSession');
          RemoveCookies('Token');
          localStorage.removeItem('resetPlace');
          dispatch(setIsUserLogin(false));
          setTimeout(() => {
            dispatch(
              showMessage({
                message: 'Logged out Successfully',
                varient: 'success',
              }),
            );
            navigate('/login', { state: { from: location } });
          }, 2000);
        }
        return Promise.reject(error);
      },
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { i18n } = useTranslation();

  return (
    <>
      <Helmet
        titleTemplate="%s - Spectacular Christmas Parties 2024 - Xmas Parties from Best Parties Ever"
        defaultTitle="Spectacular Christmas Parties 2024 - Xmas Parties from Best Parties Ever"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <Routes />
    </>
  );
}
