import ExclusiveForm from 'Components/ExclusiveForm';
import React, { lazy, useEffect, Suspense } from 'react';

import { Route, Routes } from 'react-router-dom';
import PrivateRouter from './PrivateRouter';
import { getSessionValue } from 'utils/common';
import Loader from 'Components/Common/Loader';
import Header from 'Components/Common/Header';
import Footer from 'Components/Common/Footer';
const Home = lazy(() => import('Components/index'));
const London = lazy(() => import('Components/Venue/London'));
const ExclusiveParties = lazy(() => import('Components/ExclusiveParties'));
const Login = lazy(() => import('Components/Account/Login'));
const CreateAccount = lazy(() => import('Components/Account/CreateAccount'));
const SetupAccount = lazy(() => import('Components/Account/SetupAccount'));
const ForgotPassword = lazy(() => import('Components/Account/ForgotPassword'));
const ResetPassword = lazy(() => import('Components/Account/ResetPassword'));
const RSVP = lazy(() => import('Components/Account/RSVP'));
const MyAccount = lazy(() => import('Components/Account/MyAccount'));
const Booking = lazy(() => import('Components/Account/Booking/Index'));
const Confirm = lazy(() =>
  import('Components/Account/Booking/ConfirmYourPlaces'),
);
const PayBalance = lazy(() => import('Components/Account/Booking/PayBalance'));
const FullPayBalance = lazy(() =>
  import('Components/Account/Booking/FullPayBalance'),
);
const UpdateYourPlace = lazy(() =>
  import('Components/Account/Booking/UpdateYourPlaces'),
);
const PaymentForm = lazy(() =>
  import('Components/Account/Booking/Payment/PaymentForm'),
);
const BookingTerms = lazy(() =>
  import('Components/Account/Booking/BookingTerms'),
);
const RequestBrochure = lazy(() => import('Components/About/RequestBrochure'));
const Contact = lazy(() => import('Components/About/Contact'));
const NotFoundPage = lazy(() => import('Components/NotFoundPage'));
const PaymentFailError = lazy(() => import('Components/PaymentFailError'));
const OneFeedsTwo = lazy(() => import('Components/About/OneFeedsTwo'));
const BookPlaces = lazy(() => import('Components/Account/BookPlaces'));

export default function Index() {
  useEffect(() => {
    getSessionValue();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div className="main_wrapper">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/:website_url" element={<London />} />
          <Route path="/exclusiveparties" element={<ExclusiveParties />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/setup-account" element={<SetupAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ForgotPassword />} />
          <Route path="/bpe-user/reset-password" element={<ResetPassword />} />
          <Route path="/bpe-user/setup-password?" element={<ResetPassword />} />
          <Route path="/invite/:id" element={<RSVP />} />
          <Route path="/about/:routes/:aboutId" element={<Contact />} />
          <Route path="/exclusive-form" element={<ExclusiveForm />} />
          <Route path="/one-feeds-two" element={<OneFeedsTwo />} />
          <Route path="/pagenotfound" element={<NotFoundPage />} />
          <Route path="/account/error" element={<PaymentFailError />} />
          <Route
            exact
            path="/book-places"
            element={
              <PrivateRouter>
                <BookPlaces />
              </PrivateRouter>
            }
          />
          {/* protected start */}
          <Route
            path="/account"
            element={
              <PrivateRouter>
                <MyAccount />
              </PrivateRouter>
            }
          />
          <Route
            path="/booking/:id"
            element={
              <PrivateRouter>
                <Booking />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/booking/:id/confirm"
            element={
              <PrivateRouter>
                <Confirm />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/booking/:id/confirmterms"
            element={
              <PrivateRouter>
                <BookingTerms />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/booking/:id/balance"
            element={
              <PrivateRouter>
                <PayBalance />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/booking/:id/drinks"
            element={
              <PrivateRouter>
                <PayBalance />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/booking/:id/full-pay-balance"
            element={
              <PrivateRouter>
                <FullPayBalance />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/booking/:id/change"
            element={
              <PrivateRouter>
                <UpdateYourPlace />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/request-brochures"
            element={
              <PrivateRouter>
                <RequestBrochure />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/payment"
            element={
              <PrivateRouter>
                <PaymentForm />
              </PrivateRouter>
            }
          />
          <Route
            exact
            path="/booking/:id/invoice"
            element={
              <PrivateRouter>
                <PayBalance />
              </PrivateRouter>
            }
          />
          {/* protected end */}
        </Routes>
      </div>
      <Footer />
    </Suspense>
  );
}
